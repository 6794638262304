@charset 'UTF-8';
@use './global/' as *;



.accordion {
  li {
    margin-bottom: 10px;
    .accordion-heading {
      margin-bottom: 8px;
      padding: 10px 38px 10px 12px;
      position: relative;
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0;
      background-color: #E7F7F0;
      border-radius: 6px;
      cursor: pointer;
      &:before,&:after {
        position: absolute;
        right: 15px;
        top: 50%;
        width: 18px;
        height: 2px;
        background-color: #000;
        transition: .3s;
        content: "";
      }
      &:after {
        transform: rotate(-90deg);
      }
      &.on {
        color: $white;
        background-color: $base;
        &:before,&:after {
          background-color: $white;
        }
        &:after {
          transform: rotate(0);
        }
      }
    }
    .accordion-content {
      padding-bottom: 22px;
      display: none;
    }
  }
  @include media(lg) {
    li {
      .accordion-heading {
        margin-bottom: 10px;
        padding: 14px 48px 14px 20px;
        font-size: 1.8rem;
        letter-spacing: 0.03em;
        &:hover {
          opacity: 0.7;
        }
        &:before,&:after {
          right: 20px;
          width: 22px;
        }
      }
    }
  }
}



/*
.accordion-wrapper {
  position: relative;
  width: 100%;
}



.accordion-item {
  border: 1px solid $base;
  overflow: hidden;

  &:first-of-type {
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
  }
  &:last-of-type {
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  &:not(:first-of-type) {
    border-top: none;
  }
}



.accordion-heading {
  background-color: $base;
  border-bottom: 1px solid darken( $base, 5% );
  color: $white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1em;
  outline: none;
  margin: 0;
  padding: 1em;
  position: relative;
  transition: background-color var(--base-transition);
  width: 100%;

  @include hover(){
    background-color: darken( $base, 5% );
  }

  // icon
  &::after {
    content: '';
    @include setSvgIcon( $circle-plus, $white, 1.25em );
  }

  // open
  &[aria-pressed="true"] {
    &::after {
      @include setSvgIcon( $circle-minus, $white, 1.25em );
    }
  }
}



.accordion-contents {
  overflow: hidden;
  max-height: 0;
  transition: max-height var(--base-transition);
}

.accordion-contents-inner {
  background-color: $white;
  padding: 1em;
}
*/
