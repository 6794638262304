@charset 'UTF-8';
@use './global/' as *;



.tab-wrapper {
  width: 100%;
}



.tab-labels {
  border-bottom: 1px solid $gray-300;
  display: flex;
  flex-flow: row wrap;
}



.tab-label-column {
  border-top: 1px solid $gray-300;
  border-left: 1px solid $gray-300;
  flex: 1 0 auto;
  position: relative;
  &:last-child {
    border-right: 1px solid $gray-300;
  }
}



// Button
.tab-label-item {
  background-color: $gray-200;
  cursor: pointer;
  color: $gray-400;
  display: block;
  padding: 1em 0;
  text-align: center;
  width: 100%;
  @include hover() {
    color: $gray-600;
  }
  &[aria-selected="true"] {
    background-color: $white;
    color: $gray-dark;
  }
}



.tab-contents {
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}

.tab-contents-item {
  animation: show .4s linear 0s;
  padding: 1em;
}
