@charset 'UTF-8';
@use './global/' as *;



.burger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    content: '';
    background-image: set-svg($burger, $gray-dark);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
  }

  span {
    display: block;
  }

  @include media(lg) {
    display: none;
  }
}
