@charset 'UTF-8';
@use './global/' as *;



$recruit-table-border: 1px solid #aac6ba !default;
$recruit-table-border-radius: 6px !default;



// 求人案内
.recruiting-table {
  width: 100%;
  @include media(lg) {
    border: $recruit-table-border;
    border-collapse: separate;
    border-radius: $recruit-table-border-radius;
  }
  tbody {
    tr {
      &:first-child {
        th {
          border-radius: $recruit-table-border-radius $recruit-table-border-radius 0 0;
        }
      }
      &:last-child {
        th {
          @include media(lg) {
            border-bottom: none;
            border-radius: 0 0 0 $recruit-table-border-radius;
          }
        }
        td {
          @include media(lg) {
            border-bottom: none;
          }
        }
        td:last-child {
          border-bottom: $recruit-table-border;
          border-radius: 0 0 $recruit-table-border-radius $recruit-table-border-radius;
          @include media(lg) {
            border-bottom: none;
            border-radius: 0;
          }
        }
      }
      &:only-child {
        th {
          border-radius: $recruit-table-border-radius $recruit-table-border-radius 0 0;
          @include media(lg) {
            border-radius: $recruit-table-border-radius 0 0 $recruit-table-border-radius;
          }
        }
        td:last-child {
          border-bottom: $recruit-table-border;
          border-radius: 0 0 $recruit-table-border-radius $recruit-table-border-radius;
          @include media(lg) {
            border-bottom: none;
            border-radius: 0 $recruit-table-border-radius $recruit-table-border-radius 0;
          }
        }
      }
    }

    th, td {
      border: $recruit-table-border;
      display: block;
      padding: 1em;
      @include media(lg) {
        display: table-cell;
      }
    }
    th {
      background-color: #E7F7F0;
      border-bottom: none;
      width: 100%;
      @include media(lg) {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: $recruit-table-border;
        width: auto;
      }
    }
    td {
      border-bottom: none;
      @include media(lg) {
        border-top: none;
        //border-left: none;
        border-right: none;
        border-bottom: $recruit-table-border;
      }
      &.btn-container {
        text-align: center;
      }
    }
  }
}



// 詳しく見るボタン
.btn-recruiting {
  border: 1px solid $base;
  border-radius: 6px;
  color: $base;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  padding: 1em 1em 1em 1.25em;
  text-decoration: none;
  span {
    display: block;
  }
  &::after {
    content: '';
    background-image: set-svg($chevron-right, $base);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    margin-left: .5em;
    width: 1.25em;
    height: 1.25em;
  }
}



// 採用情報
h3.recruit-heading {
  margin: 0 0 20px;
  padding: 4px 10px 6px;
  font-size: 2rem;
  border: 1px solid #E3E1DA;
  background-color: #F7F6F3;
  border-radius: 6px;
  @include media(lg) {
    margin: 0 0 29px;
    padding: 10px 18px 12px;
    font-size: 2.2rem;
    letter-spacing: 0.03em;
  }
}


ul.recruit-detail-list {
  width: 100%;
  li {
    background-color: transparent;
    border-left: $recruit-table-border;
    border-right: $recruit-table-border;
    display: flex;
    flex-direction: column;
    &.not-recruiting {
      border: none;
      &:first-child {
        border: none;
      }
    }
    &:first-child {
      border-top: $recruit-table-border;
      border-radius: 6px 6px 0 0;
      overflow: hidden;
    }
    &:last-child {
      border-bottom: $recruit-table-border;
      border-radius: 0 0 6px 6px;
      overflow: hidden;
    }
    &:only-child {
      border-radius: 6px 6px 6px 6px;
      overflow: hidden;
    }
    @include media(md){
      flex-direction: row;
      &:nth-of-type(n+2){
        border-top: $recruit-table-border;
      }
    }

    .detail-head {
      background-color: #E7F7F0;
      color: $gray-dark;
      font-weight: 500;
      padding: 1em;
      text-align: left;
      @include media(md){
        border-right: $recruit-table-border;
        display: flex;
        align-items: center;
        flex: 0 0 25%;
      }
    }

    .detail-content {
      padding: 1em;
    }

  }
}



// 応募フォームにすすむボタン
.form-btn-column {
  .btn {
    appearance: none;
    background-color: $base;
    border: $btn-border-width solid transparent;
    border-radius: 5px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: $btn-font-size-normal;
    font-weight: 400;
    line-height: 1.42857;
    text-align: center;
    text-decoration: none;
    transition: background-color var(--base-transition), color var(--base-transition);
    vertical-align: middle;
    //white-space: nowrap;
    padding: $btn-y-padding-normal ($btn-x-padding-normal * 2.5) $btn-y-padding-normal $btn-x-padding-normal;
    position: relative;
    height: 100%;
    // icon
    &::after {
      content: '';
      background-image: set-svg($chevron-right, $white);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 1.25em;
      height: 1.25em;
      position: absolute;
      top: 50%;
      right: $btn-x-padding-normal *.8;
      transform: translateY(-50%);
    }
    // hover
    @include hover(){
      background-color: lighten( $base, 5% );
      color: $white;
    }
  }
}
