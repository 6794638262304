@charset 'UTF-8';
@use '../global/' as *;



.form-verify-wrapper {
  background-color: $gray-100;
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity var(--base-transition), visibility var(--base-transition);
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 999;

  &#{$verify-visible-class} {
    opacity: 1;
    visibility: visible;
  }
}



body#{$verify-visible-class} {
  overflow: hidden;
}



.form-verify-container {
  background-color: $white;
  box-shadow: $base-box-shadow;
  border-radius: 12px;
  margin: 1em auto;
  padding: 1em;
  width: min(92%, 720px);
  @include media(md){
    padding: 3em;
  }
}



h2.verify-heading {
  color: $base;
  font-size: 1.25em;
  margin-bottom: 2em;
  text-align: center;
}



.verify-contents {
  text-align: center;
  & + & {
    border-top: 1px solid $gray-200;
    margin-top: 1em;
    padding-top: 1em;
  }
}



.verify-title {
  color: $base;
  font-size: .875em;
  font-weight: 700;
  margin-bottom: .875em;
  padding-bottom: .5em;
  position: relative;
  &::before {
    content: '';
    background-color: $base;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2rem;
    height: 2px;
  }
}



.verify-value {
  margin-top: .75em;
}



.verify-btn-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 4rem auto 0;
  width: 200px;
  @include media(md){
    flex-direction: row;
    gap: 1rem;
    margin-top: 4rem;
    width: 490px;
  }
}

.return-column, .exec-column {
  flex: 1;
  width: 100%;
  @include media(md){
    flex: 1;
    width: auto;
  }
}

.return-column {
  margin-top: 1rem;
  order: 1;
  @include media(md){
    margin-top: 0;
    order: 0;
  }
}

.exec-column {
  order: 0;
  @include media(md){
    order: 1;
  }
}



.btn-verify {
  background-color: $base;
  border: $form-btn-border-width solid transparent;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: $form-btn-font-size;
  font-weight: 500;
  line-height: inherit;
  text-align: center;
  appearance: none;
  text-decoration: none;
  transition: background-color var(--base-transition), color var(--base-transition);
  vertical-align: middle;
  white-space: nowrap;
  padding: .8em 1em;

  // hover
  @media(hover: hover){
    &:hover {
      background-color: lighten( $base, 7.5% );
      color: $white;
    }
  }

  // Outline
  &.outline {
    background-color: $white;
    border: $form-btn-border-width solid $base;
    color: $base;
    // Outline hover
    @media(hover: hover){
      &:hover {
        background-color: $base;
        color: $white;
      }
    }
  }

  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

}
