@charset 'UTF-8';
@use './global/' as *;



h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  font-weight: $headings-font-weight;
  line-height: $heading-line-height;
  margin-top: 0;
  margin-bottom: $heading-margin-bottom;
  text-rendering: optimizeLegibility;
}



h1 {
  padding-bottom: 15px;
  position: relative;
  font-size: 2.8rem;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 45px;
  &.small {
    font-size: 2.6rem;
  }
  &:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 43px;
    height: 2px;
    background-color: $base;
    content: "";
  }
  @include media(lg) {
    margin-bottom: 47px;
    padding-bottom: 25px;
    font-size: 4.2rem;
    &.small {
      font-size: 4.2rem;
    }
    &:after {
      width: 107px;
      height: 4px;
    }
  }
}



.headline1 {
  margin-bottom: 34px;
  padding: 22px 0 0 17px;
  position: relative;
  font-size: 2.2rem;
  line-height: 1.4;
  border-top: 2px solid #E3E1DA;
  background: url('/images/common/icon16.svg') no-repeat left 32px/14px auto;
  &:before {
    position: absolute;
    left: 0;
    top: -2px;
    width: 40px;
    height: 2px;
    background-color: $base;
    content: "";
  }
  @include media(lg) {
    margin-bottom: 41px;
    padding: 32px 0 0 25px;
    font-size: 3rem;
    letter-spacing: 0.03em;
    background-size: 16px auto;
    background-position: left 47px;
    &:before {
      width: 153px;
    }
  }
}



.headline2 {
  margin: 20px 0;
  padding: 4px 10px 6px;
  font-size: 2rem;
  border: 1px solid #E3E1DA;
  background-color: #F7F6F3;
  border-radius: 6px;
  @include media(lg) {
    margin: 29px 0;
    padding: 10px 18px 12px;
    font-size: 2.2rem;
    letter-spacing: 0.03em;
  }
}



.headline3 {
  margin-bottom: 13px;
  padding-left: 17px;
  color: $base;
  font-size: 2rem;
  background: url(/images/common/icon16.svg) no-repeat left 6px/12px auto;
  @include media(lg) {
    margin-bottom: 17px;
    padding-left: 24px;
    font-size: 2.2rem;
    letter-spacing: 0.03em;
    background-size: 16px auto;
    background-position: left 6px;
  }
}



.headline4 {
  margin-bottom: 10px;
  color: $base;
  font-size: 1.8rem;
  @include media(lg) {
    margin-bottom: 13px;
    font-size: 2rem;
    letter-spacing: 0.03em;
  }
}



.headline5 {
  margin-bottom: 20px;
  color: $base;
  font-size: 1.6rem;
  @include media(lg) {
    font-size: 1.8rem;
    letter-spacing: 0.03em;
  }
}



/*
h2 {
  font-size: $h2-font-size;
}



h3 {
  font-size: $h3-font-size;
}



h4 {
  font-size: $h4-font-size;
}



h5 {
  font-size: $h5-font-size;
}



h6 {
  font-size: $h6-font-size;
}
*/



// Paragraph
.lead {
  font-size: inherit;
  letter-spacing: $lead-letter-spacing;
  line-height: $lead-line-height;
  text-rendering: optimizeLegibility;
  // .lead spacer
  &:not(:last-child) {
    margin-bottom: $lead-top-spacer;
  }
}



// 注釈
.notes {
  padding-left: 1em;
  text-indent: -1em;
  &::before {
    content: '※';
    font-family: sans-serif;
  }
}
