@charset 'UTF-8';
@use './global/' as *;



.breadlist {
  margin: 0 -20px 20px;
  padding: 9px 22px;
  background-color: #F7F6F3;
  li {
    margin-right: 5px;
    display: inline;
    font-size: 1.2rem;
    &:first-child a {
      &:before {
        margin-right: 3px;
        display: inline-block;
        font-size: 1.6rem;
        content: "home";
        line-height: 1;
        vertical-align: -2px;
        font-family: 'Material Icons Outlined';
      }
    }
    a {
      margin-right: 8px;
      color: $base;
    }
  }
  @include media(lg) {
    margin: 0 0 34px;
    padding: 0;
    background: none;
    li {
      margin-right: 12px;
      font-size: 1.4rem;
      a {
        margin-right: 15px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
