@charset 'UTF-8';
@use '../global/' as *;
@use 'sass:math';



// 確認ボタン要素の領域
.form-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}



.form-btn-container {
  display: flex;
  flex-direction: column;
  margin: $form-btn-gutter * -1;

  @include media(md){
    flex-direction: row;
  }

  .btn-column {
    flex: 0 0 auto;
    padding: $form-btn-gutter;
    order: 1;
    &.sp-order-first {
      order: 0;
      @include media(md){
        order: 1;
      }
    }
  }
}



.form-btn {
  background: $base;
  border: $form-btn-border-width solid transparent;
  border-radius: $form-btn-border-radius;
  box-shadow: 0px 3px 1px -2px rgba( 0, 0, 0, .2 ),
              0px 2px 2px 0px rgba( 0, 0, 0, .14 ),
              0px 1px 5px 0px rgba( 0, 0, 0, .12 );
  cursor: pointer;
  color: $white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $form-btn-font-size;
  font-weight: 700;
  line-height: $form-btn-height;
  height: calc( $form-btn-height + $form-btn-border-width * 2 );
  outline: none;
  padding: 0 $form-btn-x-padding;
  text-align: center;
  text-decoration: none;
  transition: background-color var(--base-transition), border-color var(--base-transition);
  white-space: nowrap;
  // Hover
  &:hover {
    background: lighten( $base, 10% );
    color: $white;
  }
  // キャンセルとか
  &.outline {
    background: $white;
    border: $form-btn-border-width solid $gray-600;
    color: $gray-600;
    &:hover {
      background: $gray-600;
      color: $white;
    }
  }
  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
