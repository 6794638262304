@charset 'UTF-8';
@use './global/' as *;



.frame-border, .frame-mat {
  border-radius: var(--border-radius);
  padding: calc(1rem + .75vw);

  &:not(:last-child) {
    margin-bottom: $lead-top-spacer;
  }
}



.frame-border {
  background-color: $white;
  border: 1px solid $gray-400;

  @each $color, $value in $theme-colors {
    &.#{$color} {
      border-color: $value;
    }
  }

  @each $color, $value in $grays {
    &.#{$color} {
      border-color: $value;
    }
  }

  @each $color, $value in $commons-colors {
    &.#{$color} {
      border-color: $value;
    }
  }
}



.frame-mat {
  background-color: $gray-100;

  @each $color, $value in $theme-colors {
    &.#{$color} {
      background-color: tint-color($value, 80%);
    }
  }

  @each $color, $value in $grays {
    &.#{$color} {
      background-color: $value;
    }
  }

  @each $color, $value in $commons-colors {
    &.#{$color} {
      background-color: $value;
    }
  }
}
