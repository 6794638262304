@charset 'UTF-8';
@use './global/' as *;



.com-txt-list {
  margin-bottom: 25px;
  > li {
    padding: 0 0 11px 18px;
    position: relative;
    line-height: 1.5;
    letter-spacing: 0.03em;
    &:last-child {
      padding-bottom: 0;
    }
    &:before {
      position: absolute;
      left: 0;
      top: 7px;
      width: 8px;
      height: 8px;
      background-color: #333;
      border-radius: 50%;
      content: "";
    }
    a {
      color: #2BA59C !important;
      text-decoration: underline;
    }
    p {
      margin: 0 -5px;
      display: flex;
      flex-wrap: wrap;
      span {
        padding: 10px 5px 0;
        width: calc(100%/3);
        box-sizing: border-box;
      }
    }
  }
  &.style01 {
    li {
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: auto;
        background-color: transparent;
        border-radius: 0;
        content: "✿";
      }
    }
  }
  &.style02 {
    li {
      padding: 0 0 5px 1em;
      text-indent: -1em;
      &:before {
        display: none !important;
      }
    }
  }
  @include media(lg) {
    > li {
      line-height: 1.8;
      &:before {
        top: 12px;
      }
      a:hover {
          text-decoration: none;
      }
    }
  }
}



.notes-list {
  margin: 20px 0;
  &:last-child {
    margin-bottom: 0;
  }
  & > li {
    padding-bottom: 11px;
    line-height: 1.5;
    letter-spacing: 0.03em;
    padding-left: 1em;
    text-indent: -1em;
    &:last-child {
      padding-bottom: 0;
    }
    .bg-txt {
      display: block;
      text-indent: 0;
      background-color: #fac8d1;
    }
    li {
      margin-bottom: 0;
      text-indent: 0;
    }
    a {
      color: #2BA59C !important;
      text-decoration: underline;
    }
  }
  @include media(lg) {
    li {
      line-height: 1.8;
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}



.com-num-list {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  li {
    margin-bottom: 13px;
    padding-left: 25px;
    position: relative;
    line-height: 1.5;
    letter-spacing: 0.03em;
    list-style-type: none;
    counter-increment: list;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: counter(list) ".";
    }
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: #2BA59C !important;
      text-decoration: underline;
    }
  }
  @include media(lg) {
    li {
      line-height: 1.75;
    }
  }
}



.com-rank-list {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  li {
    margin-bottom: 13px;
    padding-left: 40px;
    position: relative;
    line-height: 1.5;
    letter-spacing: 0.03em;
    list-style-type: none;
    counter-increment: list;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: counter(list) "位";
    }
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: #2BA59C !important;
      text-decoration: underline;
    }
  }
  @include media(lg) {
    li {
      line-height: 1.75;
    }
  }
}



.com-dl {
  dt {
    margin-bottom: 6px;
    color: $base;
    font-weight: bold;
  }
  dd {
    margin-bottom: 20px;
    padding-bottom: 20px;
    letter-spacing: 0.03em;
    border-bottom: 1px solid #E3E1DA;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
    a {
      color: #2BA59C !important;
      text-decoration: underline;
    }
  }
  @include media(lg) {
    margin: 55px 0;
    overflow: hidden;
    letter-spacing: 0.03em;
    border-left: 1px solid #AAC6BA;
    dt {
      padding: 0 20px;
      width: 26.5%;
      float: left;
      text-align: center;
      box-sizing: border-box;
    }
    dd {
      margin-bottom: 0;
      padding: 0 0 26px 29%;
      border-bottom: none;
    }
  }
}



.com-link-ul {
  li {
    position: relative;
    margin-top: 8px;
    a {
      padding: 12px 10px 12px 38px;
      display: block;
      border: 1px solid #AAC6BA;
      border-radius: 6px;
      letter-spacing: 0.03em;
      background: url('/images/common/icon18.svg') no-repeat 13px center/18px auto;
      .material-icons-outlined {
        position: absolute;
        top: 6px;
        right: 6px;
        font-size: 1.4rem !important;
      }
    }
  }
  @include media(lg) {
    margin: -12px 0 58px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      margin-top: 12px;
      width: 49.2%;
      a {
        font-size: 1.8rem;
        padding: 17px 18px 19px 50px;
        background-position: 20px center;
        &:hover {
          opacity: 0.7;
        }
        .material-icons-outlined {
          font-size: 1.6rem !important;
        }
      }
    }
  }
}



.com-link-list {
  margin: -5px 0 20px;
  li {
    a {
      padding: 15px 10px 15px 27px;
      display: block;
      letter-spacing: 0.03em;
      border-bottom: 2px solid #E3E1DA;
      background: url('/images/common/icon19.svg') no-repeat left 21px/18px auto;
    }
    a:not([href^="#"]) {
      background: url('/images/common/icon18.svg') no-repeat left 21px/18px auto;
    }
    img {
      width: 100%;
      margin: 10px 0;
    }
  }
  @include media(lg) {
    margin: -20px -8px 0;
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 10px 8px 0;
      width: calc(100%/3);
      box-sizing: border-box;
      a {
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}



.com-flow {
  > li {
    margin-bottom: 43px;
    padding: 40px 20px 20px;
    position: relative;
    background-color: #F7F6F3;
    border-radius: 6px;
    &:after {
      position: absolute;
      left: 50%;
      bottom: -22px;
      width: 15px;
      height: 15px;
      margin-left: -7px;
      border-left: 2px solid $base;
      border-bottom: 2px solid $base;
      transform: rotate(-45deg);
      content: "";
    }
    &:last-child:after {
      display: none;
    }
    .ttl {
      margin-bottom: 18px;
      padding-bottom: 13px;
      font-size: 1.8rem;
      font-weight: bold;
      letter-spacing: 0;
      border-bottom: 1px solid #E3E1DA;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
      .num {
        padding: 0 15px 0 10px;
        position: absolute;
        left: -4px;
        top: -2px;
        color: $white;
        font-size: 1rem;
        font-family: $en-font-family;
        border-radius: 0 0 16px 0;
        background-color: $base;
        span {
          margin-left: 8px;
          font-size: 1.6rem;
          vertical-align: -2px;
        }
      }
    }
  }
  @include media(lg) {
    > li {
      margin-bottom: 40px;
      padding: 32px 30px 32px;
      &:after {
        bottom: -24px;
        width: 20px;
        height: 20px;
        margin-left: -10px;
      }
      .ttl {
        margin-bottom: 18px;
        padding-bottom: 18px;
        font-size: 2.2rem;
        letter-spacing: 0.03em;
        .num {
          margin-right: 13px;
          padding: 10px 12px 8px 13px;
          position: static;
          left: auto;
          top: auto;
          border-radius: 20px;
          vertical-align: 4px;
          span {
            margin-left: 6px;
            font-size: 1.8rem;
            vertical-align: -3px;
          }
        }
      }
    }
  }
}



/*
ul.list,
ol.list {

  li {
    &:nth-of-type(n+2){
      margin-top: .8em;
    }
  }

  ul, ol {
    margin-left: 1em;
  }

  // インデント
  &.indent {
    margin-left: 1em;
    ul, ol {
      margin-top: .8em;
    }
  }
}



ul.list {
  // dotted
  &.disc {
    list-style: disc outside;
    padding-left: 1.125em;
    ul {
      list-style: circle outside;
    }
  }

  // 四角 & 丸
  &.square,
  &.circle {
    li {
      line-height: inherit;
      padding-left: 1.25em;
      position: relative;
      &:before {
        content: '';
        color: inherit;
        display: inline-block;
        font-family: sans-serif;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.square {
    li {
      &:before {
        content: '■';
      }
    }
  }

  &.circle {
    li {
      &:before {
        content: '●';
      }
    }
  }

  // 注釈
  &.notes {
    li {
      line-height: inherit;
      padding-left: 1.25em;
      position: relative;
      &:before {
        content: '※';
        color: inherit;
        display: inline-block;
        font-family: sans-serif;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}



ol.list {
  list-style: revert-layer;
  padding-left: 1.25em;
}



dl.list {
  dt {
    font-weight: 700;
    &:not(:first-child){
      margin: $lead-top-spacer 0 0;
    }
  }
}
*/
