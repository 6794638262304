@charset 'UTF-8';
@use './color' as *;



// Font Size
// iOSのフォーカス時のズームを無効にするには16px以上を指定
$form-font-size: 16px;

// Form Container の max-width
$form-container-max-width: 768px !default;

// Form Container の padding
$form-container-padding: 1em !default;

// Form Container のボーダー
$form-container-border: 1px solid #DDE6ED !default;

// Form Container 同士のマージン
$form-container-gutter: 2rem;

// タイトルのバックグラウンドカラー
$title-background-color: $white !default;

// 入力のバックグラウンドカラー
$value-background-color: $white !default;

// 任意と必須
$optional-color: $white !default;
$optional-background-color: #339966 !default;
$required-color: $white !default;
$required-background-color: #b0120a !default;

// input 要素の padding
$input-x-padding: 1em !default;
$input-y-padding: .75em !default;

// Component のボーダー
$component-border-color: $gray-400 !default;
$component-border-width: 1px !default;
$component-border-radius: 8px !default;

// placeholder
$placeholder-color: $gray-600 !default; // フォントカラー
$placeholder-font-size: .75em !default; // フォントサイズ

// form-grid のカラム数
$form-grid-columns: 12 !default;

// グリッド要素同士の空白
$form-grid-gutter: 8px !default;

// チェックボックス & ラジオボタンのマージン
$radio-checked-gutter: .5rem;

// 確認画面を表示するクラス名
$verify-visible-class: '.form-verify-visible';

$form-btn-border-width : 2px;
$form-btn-border-radius: 2em;
$form-btn-font-size    : 16px;
$form-btn-height       : 52px;
$form-btn-x-padding    : 32px;
$form-btn-gutter       : 8px;
