@charset 'UTF-8';
@use './global/' as *;


.footer-wrapper {
  padding: 6.4rem 5%;
  background-color: $tres;
  @include media(md) {
    padding: 6.4rem 70px;
  }
}

.footer-container {
  display: flex;
  flex-flow: column;
  max-width: 1340px;
  margin: 0 auto;
  @include media(md) {
    flex-flow: row;
    gap: 4rem;
  }
}


.footer-left-column {
  .footer-brand {
    font-weight: 700;
    a {
      color: $gray-dark;
      @include hover(){
        color: $gray-600;
      }
    }
  }
  .footer-content {
    display: flex;
    flex-flow: column;
    margin-top: 2.5rem;
    @include media(md) {
      flex-flow: row;
    }
    img {
      width: 100%;
      @include media(md) {
        width: 50%;
      }
    }
    .address-list {
      color: $white;
      font-size: 1.5rem;
      width: 100%;
      @include media(md) {
        width: 50%;
        padding-left: 3rem;
      }
      .address {
        margin-top: 1.5rem;
      }
      .access-guide {
        margin-top: 1rem;
        a {
          color: $white;
          text-decoration: underline;
          display: flex;
          align-items: center;
          &::before {
            content: "";
            background-image: set-svg($access, #fff);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            margin-right: .5rem;
          }
            }
          }
      .tel {
        margin-top: 1rem;
      }
      .fax {
        margin-top: .5rem;
      }
    }
  }
  .pamphlet-box {
    display: flex;
    flex-flow: row;
    gap: 1rem;
    margin: 3rem 0 2rem 0;
    @include media(md) {
      margin-bottom: 0;
    }
    a {
      width: 100%;
      font-size: 1.3rem;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #55824a;
      border: $btn-border-width solid transparent;
      color: $white;
      cursor: pointer;
      font-weight: 400;
      text-align: center;
      appearance: none;
      text-decoration: none;
      transition: background-color var(--base-transition), color var(--base-transition);
      vertical-align: middle;
      padding: $btn-y-padding-normal 0.42rem;
      border-radius: 5px;
      height: 100%;
      @include media(md) {
        width: initial;
      }
      // hover
      @include hover(){
        background-color: #769b6e;
      }
      &::after {
        content: "";
        background-image: set-svg($pdf, #fff);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        min-width: 1.6rem;
        min-height: 1.6rem;
        margin-left: .5rem;
      }
      // hover
      @include hover(){
        background-color: brightness( $base, 20% );
      }
    }
  }
}


.footer-right-column {
  //display: flex;
  //align-items: center;
  //justify-content: flex-end;
  //flex: 0 0 auto;
  width: 100%;
  .map {
    iframe {
      width: 100%;
      border: 0;
      height: 320px;
      @include media(md) {
        height: 355px;
      }
    }
  }
}


.footer-banner {
  padding: 3.5rem 0;
  background-color: #fff;
  @include media(md) {
    background-color: initial;
  }
  ul {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 1rem;
    @include media(md) {
      flex-flow: row;
      justify-content: center;
    }
    li {
      a {
        display: block;
      }
    }
  }
}


.footer-legal {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  background-color: #fff;
  @include media(md) {
    padding: 3rem 0;
    background-color: initial;
  }
  .footer-legal-copyright {
    color: $gray-500;
    font-size: .9rem;
    @include media(md) {
      font-size: 1.1rem;
    }
  }
}

.footer-nav {
  position: relative;
  width: 100%;
  border-left: 1px solid $gray-400;
  border-right: 1px solid $gray-400;
  .footer-nav-inner {
    .footer-nav-parent {
      overflow: hidden;
      &:not(:first-of-type) {
        border-top: none;
      }
      .footer-local-link {
        background-color: #fff;
        border-bottom: 1px solid $gray-400;
        font-size: 1.5rem;
        color: #333;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        outline: none;
        margin: 0;
        padding: 1em;
        position: relative;
        transition: background-color var(--base-transition);
        width: 100%;
        @include hover(){
          color: $gray-500;
        }
        // icon
        &::after {
          content: '';
          //@include setSvgIcon( $circle-plus, $white, 1.25em );
          position: absolute;
          top: 35%;
          right: 2rem;
          width: 10px;
          height: 10px;
          border-top: 1px solid $base;
          border-right: 1px solid $base;
          transform: rotate(135deg);
          transition: all .3s;
        }
        // open
        &[aria-pressed="true"] {
          color: $gray-500;
          &::after {
            //@include setSvgIcon( $circle-minus, $white, 1.25em );
            top: 45%;
            transform: rotate(-45deg);
          }
        }
            }
      .footer-child-wrapper {
        overflow: hidden;
        max-height: 0;
        transition: max-height var(--base-transition);
        .footer-child-container {
          background-color: #F5F7F5;
          ul {
            li {
              position: relative;
              border-bottom: solid 1px $gray-400;
              // icon
              &::after {
                content: '';
                position: absolute;
                top: 40%;
                right: 2rem;
                width: 10px;
                height: 10px;
                border-top: 1px solid $base;
                border-right: 1px solid $base;
                transform: rotate(45deg);
              }
              a {
                display: block;
                font-size: 1.5rem;
                color: #333;
                padding: 1.5rem;
                @include hover(){
                  color: $gray-500;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media(md) {
  .footer-nav {
    max-width: 1340px;
    margin: 0 auto;
    padding: 8rem 70px 0;
    border-left: initial !important;
    border-right: initial !important;
    .footer-nav-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $gray-400;
      padding-bottom: 3.5rem;
      margin-bottom: 3.5rem;
      &:last-child {
        margin-bottom: 0;
      }
      .footer-nav-parent {
        width: 33.333333%;
        &:first-child {
          padding-right: 3rem;
        }
        &:nth-child(2) {
          padding: 0 3rem;
        }
        &:last-child {
          padding-left: 3rem;
        }
        &:not(:first-child) {
          border-left: 1px solid $gray-400;
        }
        .footer-local-link {
          padding-left: 0;
          padding-top: 0;
          margin-bottom: 1.5rem;
          background-image: linear-gradient(
            90deg,
            $base 0 10%,
            $gray-400 10%
          );
          background-repeat: no-repeat;
          background-size: 100% 1px;
          background-position: bottom;
          border-bottom: initial;
          background-color: initial;
          font-size: 1.6rem;
          &::after {
            content: none;
          }
        }
        .footer-child-wrapper {
          max-height: initial;
          .footer-child-container {
            background-color: initial;
            ul {
              li {
                border-bottom: initial;
                padding: 0;
                &::after {
                  content: none;
                }
                a {
                  font-size: 1.4rem;
                  color: $gray-600;
                  text-decoration: underline;
                  padding: 0 0 1.2rem 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*@include media(lg) {
  .footer-nav {
    .footer-nav-inner {
      .footer-nav-parent {
        width: 33.3333333%;
        &:first-child, &:nth-child(4) {
          border-left: none;
          padding-right: 3rem;
          padding-left: 0;
        }
        &:nth-child(2), &:nth-child(5) {
          border-left: 1px solid $gray-400;
          padding: 0 3rem;
        }
        &:nth-child(3), &:last-child {
          border-left: 1px solid $gray-400;
          padding-left: 3rem;
          padding-right: 0;
        }
      }
    }
  }
}*/
