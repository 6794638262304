@charset 'UTF-8';
@use './global/' as *;



.content {
  width: 100%;
  .center {
    text-align: center;
    img {
      display: inline;
    }
  }
  .left {
    text-align: left;
  }
  .green {
    color: $base;
  }
  .red {
    color: #CC0000;
  }
  .top {
    vertical-align: top;
  }
  p {
    margin-bottom: 25px;
    letter-spacing: 0.03rem;
    &:last-child {
      margin-bottom: 0 !important;
    }
    a {
      color: #2BA59C;
      text-decoration: underline;
    }
    a[href^="tel:"] {
      color: #333;
      text-decoration: none;
      cursor: default;
      pointer-events: none;
    }
    a[target="_blank"]{
      text-decoration: underline;
      &:after {
        margin-left: 5px;
        display: inline-block;
        vertical-align: -4px;
        font-size: 1.8rem;
        line-height: 1;
        font-family: 'Material Icons Outlined';
        content: "open_in_new";
      }
    }
    a[href*=".pdf"]{
      text-decoration: underline;
      &:after {
        margin-left: 5px;
        display: inline-block;
        vertical-align: -4px;
        font-size: 1.8rem;
        line-height: 1;
        font-family: 'Material Icons Outlined';
        content: "picture_as_pdf";
      }
    }
    a.pdf-link[target="_blank"] {
      &:after {
        display: none;
      }
    }
    &.right {
      text-align: right;
    }
    &.notes {
      padding-left: 1em;
      font-size: 1.4rem;
      text-indent: -1em;
    }
    &.small {
      font-size: 1.2rem;
    }
    img {
      display: inline-block;
    }
  }
  .bold {
    font-weight: bold;
  }
  .bor-txt {
    padding: 5px 10px;
    display: inline-block;
    border: 1px solid #333;
  }
  .com-img-box {
    margin-bottom: 20px;
  }
  .photo-box {
    margin-bottom: 20px;
    position: relative;
    img {
      width: 100%;
    }
    p {
      margin-top: 8px;
      font-size: 1.2rem;
    }
    & > a {
      display: block;
      color: #2BA59C;
    }
    .ttl {
      padding: 8px 10px 10px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      white-space: nowrap;
      color: $white;
      font-size: 1.2rem;
      line-height: 1.5;
      font-weight: bold;
      border-radius: 3px;
      background: $base;
      &.ttl02 {
        left: 0;
        top: auto;
        bottom: 70px;
        transform: none;
      }
    }
    .com-img-list {
      li {
        height: 40vw;
      }
    }
  }
  .com-img-box.img-box {
      .photo-box {
        width: 100px !important;
    }
  }
  .google-icon {
    margin-top: -10px;
    span {
      margin-right: 3px;
      color: #333;
      font-size: 2.4rem;
    }
    &.small {
      span {
        font-size: 1.8rem;
      }
    }
  }
  .backnumber {
    margin-top: -15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      margin-top: 15px;
      width: 48%;
      text-align: center;
      a {
        display: block;
        color: #2BA59C;
        img {
          width: 100%;
        }
      }
      p {
        margin-top: 5px;
        font-size: 1.2rem;
      }
    }
  }
  .youtube__aspect-ratio {
    max-width: 560px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    aspect-ratio: 16 / 9;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  @include media(lg) {
    width: auto;
    flex: 1;
    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &.start {
        justify-content: flex-start;
      }
      .sub-item {
        &.wid-230 {
          margin-right: 20px;
          width: 230px;
        }
      }
      .notes-list {
        flex: 1;
      }
    }
    p {
      a:hover {
        text-decoration: none;
      }
      a[target="_blank"]:hover {
        text-decoration: none;
      }
      &.small {
        font-size: 1.4rem;
      }
    }
    .photo-box {
      margin: 40px auto 25px;
      max-width: 496px;
      p {
        font-size: 1.4rem;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    .com-img-box {
      margin: 38px 0;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-start;
      .photo-box {
        margin: 0 0 0 3vw;
        width: 40%;
        position: relative;
        .com-img-list {
          li {
            height: 140px;
          }
        }
      }
      .txt-box {
        flex: 1;
      }
      &.photo-left {
        flex-direction: row;
        .photo-box {
          margin: 0 3vw 0 0;
        }
      }
    }
    .google-icon {
      span {
        margin-right: 5px;
      }
    }
    .backnumber {
      margin: -15px -10px 0;
      justify-content: flex-start;
      li {
        width: 25%;
        padding: 15px 10px 0;
        box-sizing: border-box;
        img {
          width: 100%;
        }
        p {
          font-size: 1.4rem;
        }
      }
    }
  }
}



a[href^="tel:"] {
  color: #333;
  text-decoration: none;
  cursor: default;
  pointer-events: none;
}



.com-bor-box {
  margin-bottom: 30px;
  padding: 12px 18px 23px;
  background-color: $white;
  border: 1px solid $base;
  border-radius: 6px;
  &:last-child {
    margin-bottom: 0;
  }
  .headline3 {
    margin-bottom: 10px;
  }
  @include media(lg) {
    padding: 27px 30px 30px;
  }
}



.com-bg-box {
  height: 100%;
  padding: 12px 18px 23px;
  background-color: #E7F7F0;
  border-radius: 6px;
  box-sizing: border-box;
  .headline3 {
    margin-bottom: 10px;
  }
  @include media(lg) {
    padding: 27px 30px 30px;
  }
}



hr {
  border-bottom-color: #E3E1DA;
}



.com-img-list {
  margin: -15px auto 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &.five-collumn {
    margin-top: 0;
    li {
      margin-top: 0;
      width: 50%;
      @include media(lg) {
        width: 20%;
      }
    }
  }
  &.four-collumn {
    margin-top: 0;
    li {
      margin-top: 0;
      width: 50%;
      @include media(lg) {
        width: 25%;
      }
    }
  }
  &.three-collumn {
    margin-top: 0;
    li {
      margin-top: 0;
      width: 50%;
      @include media(lg) {
        width: 33.3333%;
      }
    }
  }
  &.com-img-list01 {
    margin-top: 15px;
    @include media(lg) {
      margin-top: 0;
    }
  }
  li {
    margin-top: 15px;
    width: 48%;
    .pho {
      height: 40vw;
      @include media(lg) {
        height: 275px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
    span {
      margin-top: 10px;
      display: block;
      text-align: center;
    }
  }
}



@include media(lg) {
  .hover-op {
    &:hover {
      opacity: 0.7;
    }
  }
}
