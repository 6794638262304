@charset 'UTF-8';
@use './global/' as *;



.tab-ttl {
  margin-bottom: 12px;
  font-size: 1.4rem;
  @include media(lg) {
    font-size: 1.8rem;
  }
}



.com-table {
  overflow-x: auto;
  margin-bottom: 12px;
  .inner-table {
    border-radius: 6px;
    border: 1px solid #AAC6BA;
    width: fit-content;
    overflow: hidden;
  }
  table {
    table-layout: fixed;
    border-collapse: collapse;
    th,td {
      width: 141px;
      padding: 13px 11px;
      white-space: nowrap;;
      text-align: left;
      vertical-align: top;
      letter-spacing: 0.03em;
      border: 1px solid #AAC6BA;
      @include media(lg) {
        min-width: 141px;
      }
      &.bor-bottom-none {
        border-bottom: none;
      }
    }
    th {
      font-weight: normal;
      background-color: #E7F7F0;
      vertical-align: middle;
      &.wid01 {
        width: 5%;
        @include media(lg) {
          width: 10%;
        }
      }
    }
    thead th {
      text-align: center;
      background-color: $base;
      color: #fff;
    }
    .rightgreen_b {
      background-color: #d6e343;
    }
    .th_fontsmall {
      padding: 5px;
      font-size: 1.2rem;
    }
    .line_under {
      border-bottom: 2px solid #AAC6BA !important;
    }
    th:first-child,td:first-child {
      border-left: none;
    }
    th:last-child,td:last-child {
      border-right: none;
    }
    tr:first-child th,tr:first-child td {
      border-top: none;
    }
    tr:first-child th:first-child {
      border-radius: 6px 0 0 0;
    }
    tr:first-child th:last-child {
      border-radius: 0 6px 0 0;
    }
    tbody tr:last-child th,tbody tr:last-child td {
      border-bottom: none;
    }
    tbody tr:last-child th:first-child {
      border-radius: 0 0 0 6px;
    }
    .w20 {
      width: 20%;
    }
    .w16 {
      width: 16%;
    }
  }
  &-wide {
    overflow-x: auto;
    .inner-table {
      width: auto;
      overflow: hidden;
    }
    table {
      table-layout: auto;
      width: 100%;
      th,td {
        float: left;
        width: 100% !important;
        border-left: none;
        border-right: none;
        border-top: none;
        white-space: inherit;
      }
      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
  &-short {
    table {
      width: 100%;
    }
  }
  @include media(lg) {
    margin-bottom: 30px;
    .inner-table {
      width: auto;
    }
    table {
      width: 100%;
      table-layout: auto;
      th,td {
        width: fit-content;
        min-width: inherit;
        padding: 16px 15px;
        white-space: inherit;
      }
    }
    &.ina-table {
      table {
        th {
          width: 20%;
        }
      }
    }
    &-wide {
      .inner-table {
        width: auto;
        overflow: hidden;
      }
      table {
        table-layout: auto;
        width: 100%;
        th,td {
          float: none;
          width: auto !important;
          border-left: 1px solid #AAC6BA;
        }
        th {
          width: 25% !important;
        }
        tr:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}



.fixed-table {
  table {
    table-layout: fixed !important;
  }
}



/*
.table {
  thead
  {
    th {
      background-color: $table-th-background-color;
      border: 1px solid $table-border-color;
      color: $gray-dark;
      padding: .6em 1em;
      text-align: center;
    }
  }

  tbody {
    th, td {
      border: 1px solid $table-border-color;
    }
    th {
      background-color: $table-th-background-color;
      padding: .6em 1em;
    }
    td {
      background-color: $table-td-background-color;
      overflow-wrap : break-word;
      padding: .6em 1em;
    }
  }

  // wide
  &.table-wide {
    width: 100%;
  }

  // fixed
  &.table-fixed {
    table-layout: fixed;
  }
}



.table-block, .th-block {
  border-bottom: 1px solid $table-border-color;
  width: 100%;
  @include media(lg) {
    border: none;
    width: auto;
  }
  tbody {
    th, td {
      display: block;
      @include media(lg) {
        display: table-cell;
      }
    }
    th {
      border-bottom: none;
      width: 100%;
      @include media(lg) {
        border-bottom: 1px solid $table-border-color;
        width: auto;
      }
    }
    td {
      border-bottom: none;
      @include media(lg) {
        border-bottom: 1px solid $table-border-color;
      }
    }
  }
}



// スクロールを出すための wrapper
.table-scroll-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(md) {
    white-space: normal;
  }
}
*/
