@charset 'UTF-8';
@use '../global/' as *;



select.select {
  background-color: $white;
  background-image: set-svg( $caret-down, $gray-dark );
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  border: $component-border-width solid $component-border-color;
  border-radius: $component-border-radius;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: $form-font-size;
  outline: none;
  padding: $input-y-padding 3em $input-y-padding 1em;
  position: relative;
  transition: border-color var(--base-transition);
  width: auto;
  &::-moz-focus-inner {
    border: 0;
  }
  // error
  &.error {
    border-color: $red;
  }
}
