@charset 'UTF-8';
@use './global/' as *;



.sidebar {
  width: 100%;
  h2 {
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 1.8rem;
    background: url('/images/common/side_bg01.png') no-repeat left bottom,
                url('/images/common/side_bg02.png') no-repeat right bottom;
    background-size: 85px auto;
    background-color: #2BA56F;
    border-radius: 8px 8px 0 0;
    &.small {
      font-size: 1.6rem;
    }
    &:last-child {
      border-radius: 8px;
    }
  }
  .s-list {
    padding: 8px;
    background-color: #F7F6F3;
    li {
      border: 1px solid #E3E1DA;
      border-bottom: none;
      &:last-child {
        border-bottom: 1px solid #E3E1DA;
      }
      a {
        padding: 14px 10px 14px 38px;
        display: block;
        font-size: 1.5rem;
        letter-spacing: 0.03em;
        text-decoration: none;
        background: #fff url('/images/common/icon18.svg') no-repeat 10px center/18px auto;
      }
      &.on {
        a {
          background-color: #E7F7F0;
        }
      }
    }
  }
  @include media(lg) {
    margin-right: 50px;
    width: 248px;
    .s-list {
      li {
        a:hover {
          background-color: #E7F7F0;
        }
      }
    }
  }
}
