@charset 'UTF-8';
@use './global/' as *;



// 電話番号リンク
a[href^="tel:"] {
  color: #2BA59C !important;
  text-decoration: underline !important;
  font-weight: 600;
  margin-left: .3em;
  cursor: pointer !important;
  pointer-events: auto !important;
}

@media screen and (min-width: 992px){
  a[href^="tel:"] {
    color: #333 !important;
    text-decoration: none !important;
    cursor: default !important;
    pointer-events: none !important;
  }

  .sidebar-navigation-container {
    margin-bottom: 3rem;
  }
}



// 関連リンク
$break-point: 768px;

.kanren-link-wrapper {
  .kanren-link-container {
    .kanren-link-block {
      display: flex;
      flex-wrap: wrap;
      margin: -1rem;
      .kanren-link-column {
        width: 100%;
        padding: 1rem;
        .sitemap-column {
          @media screen and ( min-width: $break-point ){
            width: 100%;
          }
        }
        @media screen and ( min-width: $break-point ){
          width: 33.33333%;
        }
      }
      .sitemap-link-column {
        @media screen and ( min-width: $break-point ){
          width: 50% !important;
        }
      }
    }
    & + .kanren-link-container {
      margin-top: 4rem;
    }
  }
}

.link-list-group {
  .link-list-item {
    display: block;
    position: relative;
    padding: 0.7rem 2rem 0.7rem 0.75rem;
    transition: background $base;
    color: #333;
    &::before{
      content: '';
      background-image: set-svg($arrow-right, $base);
      background-repeat: no-repeat;
      background-size: contain;
      width: 0.75rem;
      height: 0.75rem;
      display: inline-block;
      position: absolute;
      right: 0.5rem;
      top: 55%;
      transform: translateY(-55%);
    }
    &:hover{
      background: $gray-100;
      border-radius: 0.5rem;
      color: $base;
    }
  }
}

.pref-heading {
  //margin-top: 1rem;
  margin-bottom: 1em;
  padding: 1.125rem 1rem;
  border-top: solid 1px #adb5bd;
  border-bottom: solid 1px #adb5bd;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.0625em;
}

.facility-heading {
  margin-top: 1rem;
  margin-bottom: 1em;
  padding: 0.5rem 1rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
  font-weight: 500;
  letter-spacing: 0.0625em;
}



// 微調整
div.list-in-list span {
  display: block;
}

div.list-in-list span:before {
  content:"\02022";
  margin-right: .4em;
}



// リクルート　リハ科
.rec_reha_table {
  table {
    td, th {
      padding: 5px !important;
    }
  }
}



// 分岐処理
.only_hospice, .instead_hospice {
  display: none;
}



// PDFボタン
ul.btn.btn_pdf {
  li {
    margin-left:auto;
    margin-right: auto;
    text-align: center;
    a {
      width: 100%;
      font-size: 110%;
    }
    a:before {
      display: none;
    }
  }
}
