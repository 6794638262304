@charset 'UTF-8';
@use '../global/' as *;
@use 'sass:math';



.form-grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc( var(--form-grid-gutter) * -.5 );
  margin-right: calc( var(--form-grid-gutter) * -.5 );
  margin-left: calc( var(--form-grid-gutter) * -.5 );
  margin-bottom: calc( var(--form-grid-gutter) * -.5 );

  // 子要素にカラムの指定がない場合はすべて1カラムになる
  > * {
    flex: 1 1 100%;
    width: 100%;
    max-width: 100%;
    padding: calc( var(--form-grid-gutter) * .5 );
  }

  // 隣接
  &:not(:last-child) {
    margin-bottom: calc( var(--form-grid-gutter) * .5 );
  }

  @each $media, $value in $breakpoints {
    @include media($media){
      @for $i from 1 through $form-grid-columns {
        $num: $i;

        .#{$media}-#{$num} {
          @include flexProperty( math.div(100%, math.div($grid-columns, $num)) );
          margin: 0;
          padding: calc( var(--form-grid-gutter) * .5 );
        }

        .#{$media}-#{$num}-center {
          @include flexProperty( math.div(100%, math.div($grid-columns, $num)) );
          margin: 0 auto;
          padding: calc( var(--form-grid-gutter) * .5 );
        }

        .#{$media}-#{$num}-offset {
          @include flexProperty( math.div(100%, math.div($grid-columns, $num)) );
          margin-left: ( math.div(100%, math.div($grid-columns, $num)) );
          padding: calc( var(--form-grid-gutter) * .5 );
        }

        .#{$media}-col-#{$num} {
          @include flexProperty( math.div(100%, $num) );
          margin: 0;
          padding: calc( var(--form-grid-gutter) * .5 );
        }
      }
    }
  }
}
