@charset 'UTF-8';
@use './global/' as *;



.grad-wrap {
  position: relative;
  margin-bottom: 2em;

  + .grad-wrap {
    margin-top: 40px;
  }
}



.grad-btn {
  z-index: 2;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 10em;
  margin: auto;
  padding: .4em 0 .6em;
  border-radius: 8px;
  background: $white;
  border: 1px solid #E3E1DA;
  //color: $white;
  font-size: 1.6rem;
  text-align: center;
  cursor: pointer;
  transition: .2s ease;
  //box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

  &::after {
    content: "続きを読む";
  }

  &:hover {
    background: #fff;
    color: $base;
  }

  .fa {
    display: inline-block;
    position: relative;
    width: 13px;
    height: 13px;
    //background: gray;
    margin-right: .5em;
    &::before,&::after {
      display: block;
      content: "";
      position: absolute;
      background: $base;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    &::after {
      transform: translate(-50%,-50%) rotate(90deg);
    }
  }
}



.grad-item {
  position: relative;
  overflow: hidden;
  height: 180px;
  transition: all .3s;
  p + p {
    margin-top: 1em;
  }

  &::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100px;

    // グラデーションで隠す範囲
    background: linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.9) 50%, #fff 100%);
  }
}



.grad-trigger {
  display: none;

  &:checked ~ {
    .grad-btn {
      display: none;
      &::after {
        content: "閉じる";
      }

      .fa {
        &::after {
          display: none;
        }
      }
    }

    .grad-item {
      height: auto;
      padding-bottom: 60px;
      transition: all .3s;
      &::after {
        display: none;
      }
    }
  }
}
