@charset 'UTF-8';
@use './global/' as *;



.construct-container {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}



.construct-header {
  background-color: $header-background-color;
  box-shadow: var(--box-shadow);
  flex: 0 0 auto;
  width: 100%;
  z-index: $z-index-header;
}



.construct-globalnav {
  background-color: $globalnav-background-color;
  flex: 0 0 auto;
  width: 100%;
  z-index: $z-index-globalnav;
}



.construct-main {
  background: url('/images/common/bg02.png') no-repeat right 0px/196px auto;
  flex: 1 0 auto;
  width: 100%;
  z-index: $z-index-main;
  //margin-top: 55px;
  padding: 106px 0 40px;
  //追記 2.27
  margin-top: 64px;
  @include media(lg) {
    background: url('/images/common/bg01.png') no-repeat left top,
                url('/images/common/bg02.png') no-repeat right top;
    background-size: 420px auto;
    //margin-top: 140px;
    padding: 82px 0 40px;
    //追記 2.27
    margin-top: 129px;
  }
}



.construct-footer {
  background-color: $footer-background-color;
  flex: none;
  width: 100%;
  z-index: $z-index-footer;
}



.container {
  @include baseContainer();
}



.section-content, .section-block {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: $section-content-gutter;
  }
}



.block {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: $block-gutter;
  }
}



.center-block {
  display: block;
  margin: 0 auto;
}



.main-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  @include media(lg) {
    flex-direction: row;
    justify-content: space-between;
  }
}



.com-inner {
  margin-bottom: 45px;
}



.sub-inner {
  margin-bottom: 30px;
  @include media(lg) {
    margin-bottom: 40px;
    &.flex {
      display: flex;
      justify-content: space-between;
    }
  }
}



.sub-item {
  margin-bottom: 25px;
  @include media(lg) {
    margin-bottom: 0;
    width: 48.7%;
    .com-txt-list {
      margin-bottom: 0;
    }
  }
}


.material-icons-outlined {
  font-family: $material-font-family;
  font-weight: normal;
  font-style: normal;
  font-size: 2.4rem !important;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
	vertical-align: top;
}
