@charset 'UTF-8';
@use '../global/' as *;



// エラー表示用
.validate {
  display: inline-block;
  visibility: hidden;

  &.valid {
    visibility: visible;
  }

  .error {
    animation: fadeIn var(--base-transition);
    background-color: $red;
    border-radius: 4px;
    box-shadow: $base-box-shadow-small;
    color: $white;
    display: inline-block;
    font-size: .875em;
    font-weight: 700;
    padding: .25em 1em;
    position: relative;
  }
  // 下部
  &.lower {
    margin-top: 8px;
    margin-left: 0;
    .error {
      &::before {
        content: '';
        border: 4px solid transparent;
        border-bottom: 4px solid $red;
        position: absolute;
        bottom: 100%;
        left: 10%;
        width: 0;
        height: 0;
      }
    }
  }
}



.h-adr {
  display: block;
}



.input-parent {
  display: flex;
  align-items: center;
  position: relative;
  & + & {
    margin-top: var(--form-grid-gutter);
  }
  .grid,
  .form-grid {
    flex: 1;
  }
}



// サブタイトルあり
.input-container {
  & + & {
    margin-top: 2rem;
  }
}

label.request {
  border-left: 6px solid $base;
  color: $gray-600;
  display: block;
  font-size: .875em;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: .5em;
  padding-left: .5em;
}



// カレンダーアイコンの表示
.icon-calendar {
  display: flex;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 3em;
  transform: translateY(-50%);
  &::before {
    content: '';
    background-image: set-svg( $calendar, $gray-400);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
  }
  // Delete アイコンが無いとき
  &.no-delete {
    right: 1rem;
  }
}



// カレンダーの入力値を削除するボタン
.delete-calendar {
  background-image: set-svg( $remove, $gray-600 );
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  width: 1.5em;
  height: 1.5em;
  transition: background-image var(--base-transition);
  @media(hover:hover){
    &:hover {
      background-image: set-svg( $remove, $gray-400 );
    }
  }
}



// display none
.display-none {
  display: none;
}



.privacy-link {
  margin-top: .5em;
  text-align: right;
  a {
    color: $gray-dark;
    display: inline-flex;
    align-items: center;
    font-size: .875em;
    &::before {
      content: '';
      @include setSvgIcon( $open-new-window, $gray-dark, 1.125em );
    }
  }
}
