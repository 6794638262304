@charset 'UTF-8';
@use './global/' as *;



.header-font-toggle {
  height: 0;
  width: 0;
  visibility: hidden;
}

.header-font-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 22px;
  background: #ddd;
  display: block;
  border-radius: 100px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 9999px;
    background: white;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
    transition: 0.3s; /*ボタンの移動時間*/
    scale: 90%; /*ボタンの縮尺指定*/
  }
}


.header-font-toggle:checked + .header-font-label {
  background: #bada55;
}

.header-font-toggle:checked + .header-font-label:after {
  left: 100%;
  translate: -100%;
}

.header-font-label:active:after {
  aspect-ratio: 1.4 / 1;
}