@charset 'UTF-8';
@use './global/' as *;



.construct-header {
  //position: sticky;
  position: fixed;
  top: 0;
  opacity: 1;
  transition: 0.5s;
  z-index: 4;
}

.header-wrapper {
  background-color: #fff;
  border-top: 2px solid #245e42;
  @include media(lg) {
    border-bottom: 1px solid #245e42;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //@include baseContainer(1rem);
  padding: .8rem 3%;
  max-width: 1340px;
  margin: 0 auto;
  container-type: inline-size;
  @include media(lg) {
    padding: 1.2rem 70px;
  }
  
  .header-left-column {
    display: flex;
    align-items: center;
    
    .header-brand {
      a {
        display: flex;
        img {
          height: 3.6rem;
          @include media(lg) {
            height: 5rem;
          }
        }
      }
    }
  }
  
  .header-right-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .5rem;
    margin-left: 1rem;
    font-size: 1rem;
    @include media(lg) {
      gap: 2rem;
    }
    
    .header-rec {
      display: none;
      color: #333;
      font-size: 1.4rem;
      @include media(lg) {
        display: flex;
        align-items: center;
      }
      &::before {
        content: "";
        background-image: set-svg($recruit, #333);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 2.1rem;
        height: 2.1rem;
        margin-bottom: .5rem;
        @include media(lg) {
          margin-right: .5rem;
          margin-bottom: initial;
        }
      }
      @include hover(){
        color: #55824a;
        &::before {
          background-image: set-svg($recruit, #55824a);
        }
      }
    }

    .header-font-resize {
      display: none;
      color: #333;
      font-size: 1.4rem;
      @container (min-width: 1018px) {
        display: flex;
        align-items: center;
      }
      p {
        margin-right: .5rem;
      }
    }

    .header-search {
      display: none;
      color: $gray-500;
      font-size: 1.4rem;
      border: solid 1px $gray-500;
      border-radius: 999px;
      padding: .5rem 1rem;
      @include media(lg) {
        display: flex;
        align-items: center;
      }
      &::before {
        content: "";
        background-image: url("/images/common/search.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }
    }

    .header-access {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #333;
      &::before {
        content: "";
        background-image: set-svg($access, #333);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin: .3rem 0 .5rem;
        @include media(lg) {
          margin: .3rem 0 .2rem;
        }
      }
      @include hover(){
        color: #55824a;
        &::before {
          background-image: set-svg($access, #55824a);
        }
      }
    }

    .header-tel {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #333 !important;
      text-decoration: none !important;
      font-weight: initial !important;
      @include media(lg) {
        flex-direction: row;
      }
      &::before {
        content: "";
        background-image: set-svg($tell, #333);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin: .3rem 0 .5rem;
        @include media(lg) {
          margin-right: .4rem;
          margin-bottom: initial;
        }
      }
      .header-tel-sm {
        @include media(lg) {
          display: none;
        }
      }
      .header-tel-lg {
        display: none;
        @include media(lg) {
          display: block;
          font-size: 2rem;
        }
      }
    }
  }
}



.global-nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 6;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  height: 100dvh;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateX(100vw);
  transition: all .3s linear;
  display: flex;
  
  
  #closeMenu {
    width: 50px;
    flex: 0 0 50px;
    .btn-close-menu {
      background-image: set-svg($close, #fff);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-top: 1rem;
    }
  }
  
  .gnav-nav-wrapper {
    width: calc(100% - 50px);
    background-color: #fff;
    flex: 1;
    .nav-search {
      margin: 2rem 3rem;
      color: $gray-500;
      font-size: 1.4rem;
      border: solid 1px $gray-500;
      border-radius: 999px;
      padding: .5rem 1rem;
      display: flex;
      align-items: center;
      @include media(lg) {
        display: none;
        align-items: center;
      }
      &::before {
        content: "";
        background-image: url("/images/common/search.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
        flex: 0 0 1.5rem;
      }
      .nav-search-inner {
        input {
          font-size: 1.6rem;
        }
      }
    }
    .gnav-nav-inner {
      background-color: #fff;
      width: 100%;
     .gnav-parent {
       .gnav-local-link {
          border-bottom: 1px solid $gray-400;
          font-size: 1.5rem;
          color: #333;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          outline: none;
          margin: 0;
          padding: 1em;
          position: relative;
          //transition: background-color var(--base-transition);
          width: 100%;
          transition: background-color var(--base-transition), color var(--base-transition);
          @include hover(){
            color: $gray-500;
          }
          &::after {
            content: '';
            position: absolute;
            top: 35%;
            right: 2rem;
            width: 10px;
            height: 10px;
            border-top: 1px solid #55824a;
            border-right: 1px solid #55824a;
            transform: rotate(135deg);
            transition: all .3s;
          }
       }
       .gnav-child-wrapper {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease-out;
          overscroll-behavior: none;
         .gnav-child-container {
           background-color: #F5F7F5;
           .gnav-child-inner {
             .gnav-child-h {
               color: #245e42;
               font-size: 1.5rem;
               padding: 1rem;
               border-bottom: solid 1px #ced4da;
               background-color: #eff5ed;
               display: flex;
               align-items: center;
               &::before {
                  content: "";
                  display: inline-block;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  width: 2.8rem;
                  height: 2.8rem;
                 margin-right: .8rem;
               }
               &.num01::before {
                 background-image: url("/images/common/nav/nav-icon01.svg");
               }
               &.num02::before {
                 background-image: url("/images/common/nav/nav-icon02.svg");
               }
               &.num03::before {
                 background-image: url("/images/common/nav/nav-icon03.svg");
               }
               &.num04::before {
                 background-image: url("/images/common/nav/nav-icon04.svg");
               }
               &.num05::before {
                 background-image: url("/images/common/nav/nav-icon05.svg");
               }
               &.num06::before {
                 background-image: url("/images/common/nav/nav-icon06.svg");
               }
             }
             .cross-h {
               color: #55824A;
               padding: 1rem 1rem 1rem 1.7rem;
               border-bottom: solid 1px #ced4da;
               position: relative;
                &::before {
                  content: "";
                  display: inline-block;
                  height: 14px;
                  width: 1.5px;
                  background-color: #55824a;
                  position: absolute;
                  top: 50%;
                  left: 1rem;
                  transform: translateY(-50%);
               }
             }
            ul {
              li {
                position: relative;
                border-bottom: solid 1px $gray-400;
                padding-right: 30px;
                // icon
                &::after {
                  content: '';
                  position: absolute;
                  top: 40%;
                  right: 2rem;
                  width: 10px;
                  height: 10px;
                  border-top: 1px solid #55824a;
                  border-right: 1px solid #55824a;
                  transform: rotate(45deg);
                }
                a {
                  display: block;
                  font-size: 1.5rem;
                  color: #333;
                  padding: 1rem;
                @include hover(){
                  color: $gray-500;
                }
                }
              }
            }
           }
         }
        }
     }
    }
  }
}

@include media(lg) {
  .global-nav {
    height: initial;
    max-width: initial;
    position: static;
    transform: initial;
    transition: initial;
    overflow-y: initial;
    overflow-x: initial;
    #closeMenu {
      display: none;
    }
    .gnav-nav-wrapper {
      width: 100%;
      flex: initial;
      background-color: #fff;
      .gnav-nav-inner {
        display: flex;
        justify-content: space-between;
        max-width: 1340px;
        margin: 0 auto;
        padding-left: 70px;
        padding-right: 70px;
       .gnav-parent {
         text-align: center;
         width: 33.3333333%;
         position: relative;
         z-index: 0;
         
         &::before {
           content: '';
           display: inline-block;
           width: 1px;
           height: 22px;
           background-color: $gray-400;
           position: absolute;
           top: 50%;
           left: 0%;
           transform: translateY(-50%);
           z-index: 1;
         }
         &:nth-child(2) {
           .gnav-child-wrapper {
             left: -100%;
           }
         }
         &:last-child {
           position: relative;
           &::after {
             content: '';
             display: inline-block;
             width: 1px;
             height: 22px;
             background-color: $gray-400;
             position: absolute;
             top: 50%;
             right: 0%;
             transform: translateY(-50%);
           }
           .gnav-child-wrapper {
             left: initial;
             right: 0;
           }
         }
         .gnav-local-link {
           display: block;
           border-bottom: initial;
           position: relative;
           font-size: 1.6rem;
           padding: 1.2rem;
           &::after {
             content: none;
           }
           @include hover() {
             background-color: #F5F7F5;
             color: #333;
           }
          }
         .gnav-child-wrapper {
           position: absolute;
           left: 0;
           top: 55px;
           width: 300%;
           height: calc(100vh - 129px);
           overflow-y: scroll;
           //スクロールバーの非表示
            /*IE(Internet Explorer)・Microsoft Edgeへの対応*/
            -ms-overflow-style: none;
            /*Firefoxへの対応*/
            scrollbar-width: none;
             /*Google Chrome、Safariへの対応*/
            &::-webkit-scrollbar{
              display: none;
            }
           box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
           border-radius: 5px 5px;
           
           .gnav-child-container {
             display: flex;
             background-color: #fff;
             padding: 1.5rem;
             text-align: left;
             .gnav-child-inner {
               width: 100%;
               padding: 1.5rem 2rem;;
               
               .gnav-child-h {
                 color: #245E42;
                 font-size: 1.6rem;
                 font-weight: initial;
                 border-bottom: none;
                 padding-left: 0;
                 margin-bottom: 1rem;
                 background-color: initial;
                 background-image: linear-gradient(
                   90deg, 
                   #245E42 0 28px, 
                   $gray-400 29px
                 );
                 background-repeat: no-repeat;
                 background-size: 100% 1px;
                 background-position: bottom;
               }
               .cross-h {
                 padding: 1.5rem 1.5rem 0 .7rem;
                 border-bottom: none;
                 &::before {
                   height: 1.6rem;
                   left: 0;
                   transform: initial;
                 }
               }
               ul {
                 li {
                   border-bottom: none;
                   padding-rigrt: 0;
                   &::after {
                     content: none;
                   }
                   a {
                     font-size: 1.6rem;
                     padding-top: 1.5rem;
                     padding-left: 0;
                     padding-bottom: 0;
                     @include hover() {
                       color: #55824a;
                     }
                   }
                 }
               }
             }
           }
          }
       }
      }
    }
  }
}

@include media(xl) {
  .global-nav {
    height: initial;
    max-width: initial;
    position: static;
    transform: initial;
    transition: initial;
    overflow-y: initial;
    .gnav-nav-wrapper {
      .gnav-nav-inner {
       .gnav-parent {
         &:nth-child(2) {
           .gnav-child-wrapper {
             left: 0;
           }
         }
         .gnav-child-wrapper {
           width: 200%;
          }
       }
      }
    }
  }
}

.nav-cover {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  background: rgba(3,3,3,.5);
}

//ナビメニューopen時
.visible-mobile-menu {
  overflow: hidden;
  height: 100%;
  position: relative;
  
  .nav-cover {
    display: block;
  }
  .global-nav {
    transform: translateX(0);
  }
}

html:has(.visible-mobile-menu){
  overflow: hidden;
}

.active {
  // open
  .gnav-local-link {
    color: $gray-500 !important;
    &::after {
      top: 45% !important;
      transform: rotate(-45deg) !important;
    }
  }
}

//スクロールによる表示・非表示
#header.remove {
  opacity: 0;
  user-select: none;
}

// ナビメニューのみ非表示の項目
.global-nav {
  .gnav-child-inner {
    ul li:has(.info-6, .info-7, .info-8, .info-9, .unit-9, .unit-10, .unit-11,  .unit-12) {
      display: none;
    }
  }
  .info-6, .info-7, .info-8, .info-9, .unit-9, .unit-10, .unit-11,  .unit-12 {
    display: none !important;
  }
}
