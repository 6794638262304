@charset 'UTF-8';
@use './global/' as *;



.btn {
  text-align: center;
  a {
    width: auto;
    min-width: 184px;
    position: relative;
    color: $white;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    appearance: none;
    text-decoration: none;
    vertical-align: middle;
    border-radius: 6px;
    padding: 12px 30px;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: 0.03em;
    background-color: $base;
    overflow: hidden;
    @include media(lg){
      min-width: 268px;
      padding: 17px 30px 21px;
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: 0.05em;
    }
    .txt {
      position: relative;
      z-index: 1;
    }
    .cover {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-radius: 50%;
      background-color: #85C3B0;
      transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
      transform: translate(-50%, -50%);
    }
    &::before {
      margin-top: -3px;
      width: 7px;
      height: 7px;
      position: absolute;
      right: 12px;
      top: 50%;
      display: inline-block;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      transform: rotate(-45deg);
      content: "";
      z-index: 2;
      @include media(lg){
        margin-top: -4px;
        width: 8px;
        height: 8px;
        right: 19px;
        border-width: 1px;
      }
    }
    &[href*=".pdf"] {
      &::before {
        display: none;
      }
      &::after {
        position: absolute;
        right: 12px;
        top: 50%;
        font-size: 1.8rem;
        line-height: 1;
        transform: none;
        font-family: 'Material Icons Outlined';
        content: "picture_as_pdf";
        transform: translateY(-50%);
      }
    }
    // hover
    @include media(lg){
      @media(hover: hover){
        &:hover {
          color: $white;
          .cover {
            width: 225%;
            height: 562.5px;
          }
        }
      }
    }
  }
  &-large {
    a {
      width: 261px;
      @include media(lg){
        width: 300px;
      }
    }
  }
  &.outline {
    a {
      color: $base;
      background-color: $white;
      border: 1px solid $base;
      &:before {
        border-color: $base;
      }
      @include media(lg) {
        &:hover {
          color: $white;
          &:before {
            border-color: $white;
          }
        }
      }
    }
  }
}



ul.btn {
  margin-top: -10px;
  li {
    margin-top: 10px;
    &.outline {
      a {
        color: $base;
        background-color: $white;
        border: 1px solid $base;
        &:before {
          border-color: $base;
        }
        @include media(lg) {
          &:hover {
            color: $white;
            &:before {
              border-color: $white;
            }
          }
        }
      }
    }
  }
  @include media(lg) {
    margin: -20px 0 25px;
    display: flex;
    flex-wrap: wrap;
    li {
      margin: 12px 12px 0 0;
      a {
        border-radius: 8px;
      }
    }
    &.small {
      li {
        a {
          width: 184px;
          min-width: auto;
          padding: 7px 30px 11px;
        }
      }
    }
  }
}



.reserve-btn {
  text-align: center;
  a {
    position: relative;
    color: $white;
    cursor: pointer;
    display: block;
    text-align: center;
    appearance: none;
    text-decoration: none;
    border-radius: 6px;
    padding: 24px 36px;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: 0.03em;
    background-color: $base;
    overflow: hidden;
    transition: opacity .3s;
    .cover {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-radius: 50%;
      background-color: #85C3B0;
      transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
      transform: translate(-50%, -50%);
    }
    .txt {
      position: relative;
      z-index: 1;
      display: inline-flex;
      align-items: center;
    }
    .material-icons-outlined {
      margin-right: 5px;
    // 	z-index: 1;
    // 	position: absolute;
    // 	right: 15px;
    // 	top: 50%;
    // 	transform: translateY(-50%);
    }
    @include media(lg){
      font-size: 2rem;
    }

    // hover
    @include media(lg){
      @media(hover: hover){
        &:hover {
          opacity: .5;
        }
      }
    }
  }
}
