@charset 'UTF-8';
@use './global/' as *;



.c-timeline__item {
  position: relative;
  display: flex;
  gap: 2.2rem;
  //outline: solid 1px;
  &:last-child {
    .c-timeline__content {
      &:before {
        display: none;
      }
    }
  }
}



.c-timeline__content {
  flex: 1;
  position: relative;
  order: 1;
  padding-left: 2.2rem;
  padding-bottom: 3rem;

  &:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 5px;
    height: 100%;
    width: 2px;
    background-color: $base;
  }

  &:after {
    content: "";
    position: absolute;
    left: calc(0px - 11px);
    top: 5px;
    width: 20px;
    height: 20px;
    background-color: white;
    z-index: 1;
    border: 2px solid $base;
    border-radius: 50%;
  }
}



.c-timeline__title {
  color: $base;
  font-weight: bold;
  font-size: 1.1em;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0.5rem;
}



.c-timeline__title.spacer {
  margin-top: 1rem !important;
}



.c-timeline__desc {
  color: #333;
}



time {
  text-align: end;
  flex: 0 0 60px;
  min-width: 0;
  overflow-wrap: break-word;
  padding-bottom: 1rem;
}
