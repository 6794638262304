@charset 'UTF-8';
@use './global/' as *;



:root {

  // root font size
  @each $key, $size in $root-font-size {
    @include media($key, true){
      --root-font-size: #{$size};
    }
  }

  // grid
  --grid-gutter: #{$grid-gutter};

  // form grid
  --form-grid-gutter: #{$form-grid-gutter};

  // color
  @each $name, $color in $all-colors {
    --#{$name}-color: #{$color};
  }

  --box-shadow: #{$base-box-shadow};
  --box-shadow-small: #{$base-box-shadow-small};
  --box-shadow-large: #{$base-box-shadow-large};
  --base-transition: #{$base-transition};
  --border-radius: #{$base-border-radius};

}





html {
  //font-size: var(--root-font-size);
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}



body {
  background-color: $body-background-color;
  color: $base-text-color;
  font-family: $base-font-family;
  //font-size: $base-font-size;
  font-size: 1.4rem;
  line-height: $base-line-height;
  min-width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and ( -webkit-min-device-pixel-ratio: 2 ), ( min-resolution: 2dppx ) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @include media(lg) {
    font-size: 1.6rem;
  }
}



// Links
a {
  color: $base-link-color;
  cursor: revert;
  text-decoration: $base-link-decoration;
  @include hover() {
    color: $base-link-hover-color;
    text-decoration: $base-link-hover-decoration;
  }
}



// Horizontal line
hr {
  border-top: 0;
  border-bottom: $hr-border;
  border-left: 0;
  border-right: 0;
  clear: both;
  margin: $hr-y-gutter 0;
  width: 100%;
  height: 0;
}



b,
em,
strong {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}
